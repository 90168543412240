



















































import { Component, Mixins } from 'vue-property-decorator';
import { AsyncView, View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import OfficeMap from '@/components/OfficeMap.vue';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
    ItemCycler,
    OfficeMap,
  },
})
export default class HomeBuyersWide extends Mixins(AsyncView) {
    private formCtx: Context | null = null

    private office: Office | null = null;

    mounted() {
      API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
        this.office = office;
      });

      const ctx: Context = {
        ClientWebsiteId: Config.Website.Id,
        Items: [
          {
            Type: ContextItemType.EnquiryTargetProfile,
            Id: '42868056-5c47-4fee-88b1-1d796a69bd99',
          },
        ],
      };

      this.formCtx = ctx;
    }
}
